import service from "@/fetch";
import store from "@/store";

export const getUserInfo = async (merchantId) => {
  if (store.state.userInfo && store.state.userInfo.userId) {
    return;
  }
  const {
    data: { data },
  } = await service.post("/mc/api/user/account/detail", {
    merchantId,
  });
  store.commit("updateUserInfo", data);
  return data;
};

export const getMyCards = async (merchantId) => {
  const { data } = await service.get("/mc/api/user/card/list", {
    params: { merchantId },
  });
  return data;
};

export const getMemberCards = async (merchantId) => {
  const { data } = await service.post("/mc/api/base/card/list", {
    merchantId,
  });
  return data;
};

export const getCardDetail = async (params) => {
  const { data } = await service.post("/mc/api/base/card/detail", {
    ...params,
  });
  return data;
};

export const getServiceTel = async () => {
  const { data } = await service.get("/mc/api/service/tel");
  return data;
};

export const logout = async () => {
  const { data } = await service.get("/mc/api/logout");
  return data;
};

export const getAllBenefits = async (merchantId) => {
  const { data } = await service.get("/mc/api/base/card/benefit/list", {
    params: { merchantId },
  });
  return data;
};

export const getBenefitUrl = async (merchantId) => {
  const { data } = await service.get("/mc/api/system/getBenefitUrl", {
    params: { merchantId },
  });
  return data;
};

export const hasCards = async (merchantId) => {
  const { data = {} } = await service.get("/mc/api/user/card/list", {
    params: { merchantId },
  });
  const { data: list = [] } = data;
  return !!list.length;
};
